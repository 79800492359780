import React, { useState, useEffect } from 'react';
import axios from 'axios';
// import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form/dist/index';
import PhoneInput from 'react-phone-input-2';
// import flags from 'react-phone-number-input/flags';
// import { serialize } from 'object-to-formdata';
import parsePhoneNumber from 'libphonenumber-js';
import {
  sendEmailVerificationCode,
  verifyEmailVerificationCode,
  verifyPhoneNumber,
  verifyPhoneNumberOTP,
  updateUser,
  destroyUserBanner,
  destroyUserAttachment,
  updateUserBanner
} from '../api/user';
import VerificationForm from './emailVerificationForm';
// import mola_subscriber from './img/mola_subscriber';
// import { UploadProfileImageButton } from './newProfileEdit/UploadComponent';
import ModalContainer from './newProfileEdit/ModalContainer';
import { ProfileModalContainer } from './newProfileEdit/ModalDesign/ProfileModal';
import CropImageModal  from './newProfileEdit/CropImage/Container';
// import { UploadBannerModalContainer } from './newProfileEdit/ModalDesign/BannerModal';

export default function UserProfileEditForm(props) {
  const { user, user_attachment, user_banner, ind_ktp_id_image } = props;
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    // getValues,
    setError,
  } = useForm();
  const [validPhone, setValidPhone] = useState(true);
  const [validEmail, setValidEmail] = useState(true);
  const [molaSub, setMolaSub] = useState(false);
  const [verifiedEmail, setVerifiedEmail] = useState(user.verified_email);
  const [bannerData, setBannerData] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [verifiedPhone, setVerifiedPhone] = useState(
    user.verified_phone_number
      ? user.verified_phone_number.replace('+', '')
      : ''
  );
  const [profileModal, setProfileModal] = useState(false);
  const [bannerModal, setBannerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [showPhoneVerification, setShowPhoneVerification] = useState(false);
  const [emailValidity, setEmailValidity] = useState(
    user.email_code_validity ? Date.parse(user.email_code_validity) : null
  );
  const [otpValidity, setOtpValidity] = useState(
    user.otp_validity ? Date.parse(user.otp_validity) : null
  );
  // const [phoneValidity, setPhoneValidity] = useState(false);
  function addServerErrors(errors, setError) {
    return Object.keys(errors).forEach((key) => {
      setError(key, {
        type: 'server',
        message: errors[key].join('. '),
      });
    });
  }
  const onSubmit = (data, field = null, debounce = false) => {
    setLoading(true);
    let formUser = new FormData();
    if (data.attachment && data.attachment[0]) {
      formUser.append('user[attachment]', data.attachment[0]);
    }
    if (data.banner && data.banner[0]) {
      formUser.append('user[banner]', data.banner[0]);
    }
    if (data.ind_ktp_id_image && data.ind_ktp_id_image[0]) {
      formUser.append('user[ind_ktp_id_image]', data.ind_ktp_id_image[0]);
    }
    if (data.name) formUser.append('user[name]', data.name);
    if (data.date_of_birth) formUser.append('user[date_of_birth]', data.date_of_birth);
    if (data.gender) formUser.append('user[gender]', data.gender);
    if (data.fullname) formUser.append('user[fullname]', data.fullname);
    if (data.email) formUser.append('user[email]', data.email);
    if (data.bio) formUser.append('user[bio]', data.bio);
    if (data.personal_url)
      formUser.append('user[personal_url]', data.personal_url);
    if (data.twitter_link)
      formUser.append('user[twitter_link]', data.twitter_link);
    if (data.ind_ktp_id) formUser.append('user[ind_ktp_id]', data.ind_ktp_id);
    if (data.phone_number) {
      formUser.append(
        'user[phone_number]',
        `+${data.phone_number.replace('+', '')}`
      );
    }
    updateUser(formUser, token, user.id).then(
      (res) => {
        setLoading(false);
        const fieldName =
          typeof field === 'string' || field instanceof String
            ? `User ${field}`
            : 'Profile';
        toastr.success('updated successfully');
        if (!field) {
          location.reload();
        } else {
          if (!debounce) window.location.replace(document.referrer);
        }
      },
      (err) => {
        setLoading(false);
        if (err.response.data.errors) {
          addServerErrors(err.response.data.errors, setError);
        }
        console.log(err);
      }
    );
  };
  const currentEmailValue = watch('email', user.email);
  const currentPhoneValue = watch(
    'phone_number',
    user.phone_number ? user.phone_number.replace('+', '') : ''
  );
  const validateEmail = (e) => {
    const valid = !!e.target.value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    setValidEmail(valid);
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = parsePhoneNumber(`+${e}`);
    setValidPhone(!!phoneNumber);
  };

  useEffect(() => {
    const element = document.querySelector('[name=csrf-token]');
    if (element) {
      setToken(element.content);
    }
  });
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await fetch(
        `https://api2-mola.onwards.pro/v1/subscriber/queryuser?email=${currentEmailValue}`,
        {
          mode: 'cors',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: 'Basic YWZmaWxpYXRlQG1vbGEudHY6TW9sYTIwMjI=',
          },
        }
      );
      let result = response.json();
      let activeSub = await result;
      if (activeSub){
        setIsLoading(false)
        setMolaSub(activeSub.activeSubscription)
        const token = document.querySelector('[name=csrf-token]');
      await axios.put('/walletless-mola-subscriber-check', {
        authenticity_token: token.content,
        mola_sub: activeSub.activeSubscription,
        email: currentEmailValue,
      });
      }
      
    })();
  }, [molaSub]);
  console.log(isLoading, 'loading')
  console.log(molaSub, 'sub')
  // useEffect(() => {
  //   const token = document.querySelector('[name=csrf-token]');
  //   const checkMolaSub = async () => {
  //     const userUpdate = await axios.put('/walletless-mola-subscriber-check', {
  //       authenticity_token: token.content,
  //       mola_sub: molaSub,
  //       email: currentEmailValue,
  //     });
  //   };
  //   return checkMolaSub();
  // }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    const data = {
      email: currentEmailValue,
    };
    if ((emailValidity && Date.now() > emailValidity) || !emailValidity) {
      sendEmailVerificationCode(data, token).then(
        (res) => {
          // setVerifiedEmail(false);
          toastr.success(res.message);
          setEmailValidity(Date.parse(res.code_validity));
          setShowEmailVerification(true);
        },
        (err) => {
          const error = {
            error: true,
            message: 'Please input a valid email',
            status: '',
          };
          toastr.error(error.message);
          console.log(err);
        }
      );
    } else {
      setShowEmailVerification(true);
    }
  };

  const verifyEmail = (code) => {
    verifyEmailVerificationCode(
      { email: currentEmailValue, molaSub },
      code,
      token
    ).then(
      (res) => {
        setVerifiedEmail(currentEmailValue);
        toastr.success('Email verified successfully');
        setShowEmailVerification(false);
      },
      (err) => {
        toastr.error(err.response.data.message);
        console.log(err);
      }
    );
  };

  const verifyPhoneNumberCode = (code) => {
    verifyPhoneNumberOTP(
      { phone_number: `+${currentPhoneValue.replace('+', '')}` },
      token,
      code
    ).then(
      (res) => {
        setVerifiedPhone(currentPhoneValue);
        toastr.success('Phone number verified successfully');
        setShowPhoneVerification(false);
      },
      (err) => {
        toastr.error(err.response.data.message);
        console.log(err);
      }
    );
  };

  const sendSMS = (e) => {
    e.preventDefault();
    const data = { phone_number: `+${currentPhoneValue.replace('+', '')}` };
    if ((otpValidity && Date.now() > otpValidity) || !otpValidity) {
      verifyPhoneNumber(data, token).then(
        (res) => {
          if (res.message !== 'This phone number already exists for another user'){
            toastr.success(res.message);
            setOtpValidity(Date.parse(res.otp_validity));
            setShowPhoneVerification(true);
          } else {
            toastr.error(res.message)
          }
        },
        (err) => {
          const error = {
            error: true,
            message: err.response.data.message,
            status: '',
          };
          toastr.error(error.message);
          console.log(err);
        }
      );
    } else {
      setShowPhoneVerification(true);
    }
  };

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const fieldAutoSaveOnChange = (e) => {
    // console.log(e)
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const data = {};
    data[fieldName] = fieldValue;
    console.log(data);
    onSubmit(data, fieldName, true);
  };

  const handleDeleteBanner = async () => {
    const token = document.querySelector('[name=csrf-token]');
    const res = await destroyUserBanner(token.content, user.id);
  };
  const handleDeleteAttachment = async () => {
    const token = document.querySelector('[name=csrf-token]');
    destroyUserAttachment(token.content, user.id);
  };

  const canEdit = user.fullname && user.ind_ktp_id && ind_ktp_id_image !== '/assets/dummy-image.jpg';
  const canVerifyPhone = user.phone_number_verified
  return (
    <>
      <ProfileModalContainer
        register={register}
        user_attachment={user_attachment}
        closeModal={() => setProfileModal(false)}
        open={profileModal}
        handleDeleteAttachment={handleDeleteAttachment}
        user={user}
        updateUser={updateUserBanner}
      />

      <CropImageModal
        closeModal={() => {
          setBannerModal(false);
          setBannerData();
        }}
        user={user}
        confirmClose={() => setBannerModal(false)}
        open={bannerModal}
        setBannerData={setBannerData}
        user_banner={user_banner}
        handleDeleteBanner={handleDeleteBanner}
        // handleSubmit={onSubmit}
        updateUserBanner={updateUserBanner}
      />

      <div className='formBg'>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete='none'>
          <div className=' w-full'>
            <div
              id='bannerPreview'
              className='relative w-full min-h-[300px] grid items-start p-8 mx-0 profile-cover-style coverBg'
              style={{
                backgroundImage: `url(${user_banner})`,
                backgroundPosition: 'center center',
                backgroundSize: 'center',
              }}
            >
              <div
                onClick={() => setBannerModal(!bannerModal)}
                className='justify-self-end bg-white/30 hover:bg-white hover:text-[#081F3A] text-[#081F3A] transition-all duration-300 ease border py-2 px-6 text-[17px] cursor-pointer'
              >
                Unggah Banner
              </div>
              {/* <div className='w-full h-full overflow-hidden '>{(cover|| imageCoverFile?.view)&&
                <img src={cover ?? imageCoverFile?.view } className='w-full  h-[200px]' alt={`cover`}/>}
            </div> */}
              <div className='profileImage  h-[120px] w-[120px] rounded-full shadow-xl bg-white z-10'>
                <div className='h-[120px] w-[120px] rounded-full overflow-hidden '>
                  <div
                    id='imagePreview'
                    style={{
                      backgroundImage: `url(${user_attachment})`,
                      backgroundPosition: 'center center',
                      backgroundSize: '100%',
                      backgroundRepeat: 'no-repeat',
                    }}
                    className='h-[120px] w-[120px] rounded-full object-cover z-0'
                  />
                </div>
                {/* profile upload modal */}
                {/* <ModalContainer
                  closeModal={() => setProfileModal(false)}
                  openModal={profileModal}
                  overlay={'modal-overlay'}
                  className={'modal-container'}
                >
                  <ProfileModalContainer
                    closeModal={() => setProfileModal(false)}
                    user_attachment={user_attachment}
                    register={register}
                  />
                </ModalContainer> */}
                <div>
                  <div
                    className='z-20 icon-camera cursor-pointer '
                    onClick={() => setProfileModal(!profileModal)}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-4 h-4  text-white'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M6.827 6.175A2.31 2.31 0 015.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 00-1.134-.175 2.31 2.31 0 01-1.64-1.055l-.822-1.316a2.192 2.192 0 00-1.736-1.039 48.774 48.774 0 00-5.232 0 2.192 2.192 0 00-1.736 1.039l-.821 1.316z'
                      />
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M16.5 12.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zM18.75 10.5h.008v.008h-.008V10.5z'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='edit-form px-4 mx-auto flex flex-col gap-4 !pb-20'>
            <h1 className='section__title !mb-0 !mt-32'>Profil Lengkap</h1>

            <div className='grid sm:grid-cols-2 grid-cols-1 gap-4'>
              <div className='w-full flex flex-col'>
                <label
                  htmlFor='name'
                  className={`text-base text-[#081F3A] capitalize ${
                    false && 'text-red-500'
                  }`}
                >
                  Username Pengguna Akun
                  {true && <span className='requiredArt'>* (wajib)</span>}
                </label>
                <input
                  type='text'
                  className={`editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
                    false && 'border-red-700'
                  }`}
                  name='name'
                  ref={register({
                    required: 'Please enter your username',
                  })}
                  defaultValue={user.name}
                  placeholder='Account User Name'
                  autoComplete='false'
                  onKeyUp={debounce((e) => fieldAutoSaveOnChange(e), 500)}
                />
                {errors.name && (
                  <span className='form-error-text'>{errors.name.message}</span>
                )}
              </div>

              <div className='w-full flex flex-col'>
                <label
                  htmlFor='name'
                  className={`text-base text-[#081F3A] capitalize ${
                    false && 'text-red-500'
                  }`}
                >
                  Nama Lengkap Sesuai ID
                  {true && <span className='requiredArt'>*(wajib)</span>}
                </label>
                <input
                  disabled={canEdit}
                  type='text'
                  className={`editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
                    false && 'border-red-700'
                  }`}
                  name='fullname'
                  ref={register({
                    required: 'Please enter your legal name',
                  })}
                  defaultValue={user.fullname}
                  placeholder='Legal Name as on ID'
                  autoComplete='false'
                  onKeyUp={debounce((e) => fieldAutoSaveOnChange(e), 500)}
                />
                {errors.fullname && (
                  <span className=' form-error-text'>
                    {errors.fullname.message}
                  </span>
                )}
              </div>
            </div>

            <div className='grid sm:grid-cols-2 grid-cols-1 gap-4'>
              <div className='w-full flex flex-col'>
                <label
                  htmlFor='name'
                  className={`text-base text-[#081F3A] capitalize ${
                    false && 'text-red-500'
                  }`}
                >
                  Email Anda
                  {true && <span className='requiredArt'>* (wajib)</span>}
                </label>
                <div
                  className={`editInput relative placeholder:text-[#BDC1C5] border border-[#B0CAE8] h-[45px] focus:outline-none  rounded  ${
                    false && 'border-red-700'
                  }`}
                >
                  <input
                    type='text'
                    name='email'
                    defaultValue={user.email}
                    ref={register({
                      required: true,
                    })}
                    placeholder='johndoe@email.com'
                    className={`border-none focus:outline-none active:outline-none w-full`}
                    onChange={validateEmail}
                  />
                  <span className='sign__verify'>
                    {currentEmailValue &&
                      currentEmailValue != verifiedEmail &&
                      validEmail && (
                        <button
                          className='sign__verify-btn'
                          onClick={sendEmail}
                        >
                          {' '}
                          Verifikasi
                        </button>
                      )}
                    {currentEmailValue &&
                      currentEmailValue == verifiedEmail && (
                        <button className='sign__verify-btn' disabled>
                          {' '}
                          Terverifikasi
                        </button>
                      )}
                  </span>
                  {errors.email && (
                    <span className=' form-error-text'>
                      {errors.email.message}
                    </span>
                  )}
                </div>
              </div>
              <div className='w-full flex flex-col'>
                <label
                  htmlFor='name'
                  className={`text-base text-[#081F3A] capitalize ${
                    false && 'text-red-500'
                  }`}
                >
                  Nomor Telepon Mobile
                </label>
                <div
                  style={{
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <Controller
                    control={control}
                    name='phone_number'
                    // rules={{ required: true }}
                    defaultValue={user.phone_number}
                    render={(prop) => (
                      <>
                        <PhoneInput
                          {...prop}
                          country={'us'}
                          onChange={(e) => {
                            handlePhoneChange(e);
                            prop.onChange(e);
                          }}
                          inputStyle={{
                            border: 'none',
                            width: '100%',
                            height: '45px',
                          }}
                          buttonStyle={{
                            border: 'none',
                            background: 'transparent',
                          }}
                        />
                      </>
                    )}
                  />
                  <span className='sign__verify'>
                    {currentPhoneValue &&
                      currentPhoneValue.replace('+', '') !=
                        verifiedPhone.replace('+', '') &&
                      validPhone && (
                        <button className='sign__verify-btn' onClick={sendSMS}>
                          {' '}
                          Verifikasi
                        </button>
                      )}
                    {currentPhoneValue &&
                      currentPhoneValue.replace('+', '') ==
                        verifiedPhone.replace('+', '') && (
                        <button className='sign__verify-btn' disabled>
                          {' '}
                          Terverifikasi
                        </button>
                      )}
                  </span>
                  {errors.phone_number && (
                    <span className=' form-error-text'>
                      {errors.phone_number.message}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className='grid sm:grid-cols-2 grid-cols-1 gap-4'>
              <div className='w-full flex flex-col'>
                <div className='w-full flex flex-col'>
                  <label
                    htmlFor='name'
                    className={`text-base text-[#081F3A] capitalize ${
                      false && 'text-red-500'
                    }`}
                  >
                    Nomor ID Anda{' '}
                    {true && <span className='requiredArt'>* (wajib)</span>}
                  </label>
                  <input
                    disabled={canEdit}
                    type='text'
                    name='ind_ktp_id'
                    defaultValue={user.ind_ktp_id}
                    ref={register()}
                    className={`editInput  placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
                      false && 'border-red-700'
                    }`}
                    id='twitter-username'
                    placeholder='ID Number'
                    onKeyUp={debounce((e) => fieldAutoSaveOnChange(e), 500)}
                  />
                  <p id='twitt_link' className='form-error-text'>
                    Maksimal 16 karakter.
                  </p>
                </div>
                {errors.ind_ktp_id && (
                  <span className=' form-error-text'>
                    {errors.ind_ktp_id.message}
                  </span>
                )}
              </div>
              {!isLoading && <div className='flex flex-col w-full'>
                <label
                  htmlFor='mola_subscriber'
                  className={`text-base text-[#081F3A] new_label__profile capitalize ${
                    false && 'text-red-500'
                  }`}
                >
                  Mola TV Subscriber
                </label>
                <input
                  type='text'
                  className={`sub-mola  placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
                    false && 'border-red-700'
                  }`}
                  name='mola_subscriber'

                  defaultValue={user.is_mola_sub? ' YA' : 'TIDAK'}
                  disabled
                />

                  </div>}
            </div>
             <div className='grid grid-cols-1 gap-4 sm:grid-cols-2'>
              {/* date of birth */}
            <div className='flex flex-col w-full'>
              <label
                htmlFor='name'
                className={`text-base text-[#081F3A] new_label__profile capitalize ${
                    false && 'text-red-500'
                 }`}
                >
                 Tanggal Lahir{true && <span className='requiredArt'>*</span>}
                </label>
                <div
                  className={`editInput relative placeholder:text-[#BDC1C5] border border-[#B0CAE8] h-[45px] focus:outline-none  rounded  ${
                    false && 'border-red-700'
                  }`}
                >
                  <input
                    type='date'
                    name='date_of_birth'
                    defaultValue={user.date_of_birth}
                    ref={register({
                      required: 'Please enter your birth date',
                    })}
                    data-date-format="YYYY MMMM DD"
                    className={`border-none focus:outline-none active:outline-none w-full`}
                    // onChange={validateEmail}
                  />
                 {errors.date_of_birth && (
                    <span className=' form-error-text'>
                      {errors.date_of_birth.message}
                    </span>
                  )}
                </div>
              </div>
              {/* gender */}
              <div className='flex flex-col w-full'>
                <label
                  htmlFor='name'
                  className={`text-base text-[#081F3A] new_label__profile capitalize ${
                    false && 'text-red-500'
                  }`}
                >
                  Jenis Kelamin{true && <span className='requiredArt'>*</span>}
                </label>
                <div
                  className={`editInput relative placeholder:text-[#BDC1C5] border border-[#B0CAE8] h-[45px] focus:outline-none  rounded  ${
                    false && 'border-red-700'
                  }`}
                >
                  <select
                   className={`border-none focus:outline-none active:outline-none w-full`}
                   defaultValue={user.gender}
                   name='gender'
                   ref={register({
                    required: 'Please select gender',
                  })}
                  >
                    <option value=''>Choose Gender</option>
                    <option value='male'>Male</option>
                    <option value='female'>Female</option>
                  </select>
                     {errors.gender && (
                    <span className=' form-error-text'>
                      {errors.gender.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="sign__group lik_hold t_link">
                    <label
                      className="sign__label"
                      htmlFor="twitter_username"
                    >
                      Twitter Username
                    </label>
                    <input
                      type="text"
                      name="twitter_link"
                      defaultValue={
                        user.twitter_link
                      }
                      ref={register()}
                      className="sign__input"
                      id="twitter-username"
                    />
                    <span>@</span>
                    <span id="twitt_link">
                      Link
                    </span>
                    <p>
                      Link your Twitter
                      account in order
                      to get the
                      verification badge
                    </p>
                  </div>
                  {errors.twitter_link && (
                    <span className=" form-error-text">
                      {
                        errors
                          .twitter_link
                          .message
                      }
                    </span>
                  )}
                </div> */}

            {/* <div className='col-12 col-md-6 col-lg-6 col-xl-6'> */}
            {/* <div className="sign__group lik_hold">
                    <label
                      className="sign__label"
                      htmlFor="portfolio_url"
                    >
                      Personal Portfolio
                    </label>
                    <input
                      type="text"
                      name="personal_url"
                      defaultValue={
                        user.personal_url
                      }
                      className="sign__input"
                      ref={register()}
                      id="personal-site-or-portfolio"
                      placeholder="Personal Portfolio"
                    />
                    <span>
                      https://
                    </span>
                  </div>
            {errors.personal_url && (
              <span className=' form-error-text'>
                {errors.personal_url.message}
              </span>
            )}*/}
            {/* <div className="d-flex flex-column hidden">
                    <label className="sign__label" htmlFor="mola_subscriber">
                      Mola Subscriber
                    </label>
                    {(user.is_mola_sub) && (
                      <div className="d-flex mt-1">
                        <img
                          src={`${mola_subscriber}`}
                          alt="Mola"
                          className=" mr-2 mola_sub_img"
                        />
                        <h5>
                          Mola Subscriber:&nbsp;
                          <span
                            style={{
                              color: `#388E3C`,
                            }}
                          >
                            YES
                          </span>
                        </h5>
                      </div>
                    )}
                    {!user.is_mola_sub && (
                      <div className="d-flex mt-1">
                        <img
                          src={`${mola_subscriber}`}
                          alt="Mola"
                          className=" mr-2 mola_sub_img"
                        />
                        <h5>
                          Mola Subscriber:&nbsp;
                          <span
                            style={{
                              color: `#B71C1C`,
                            }}
                          >
                            NO
                          </span>
                        </h5>
                      </div>
                    )}
                  </div> */}

            <div className=' w-full my-8'>
              <div className='' data-text='Change Profile Image'>
                <div className='avatar-upload !items-start !w-full '>
                  <div className='avatar-preview ktp_preview'>
                    <div
                      id='ktpPreview'
                      style={{
                        backgroundImage: `url(${ind_ktp_id_image})`,
                      }}
                    ></div>
                  </div>
                  <div className={canEdit ? 'flex flex-col !w-full hidden' : 'flex flex-col !w-full'}>
                    <input
                      disabled={canEdit}
                      name='ind_ktp_id_image'
                      type='file'
                      ref={register()}
                      className='form-control-file hidden'
                      id='ktpUpload'
                    />
                    <label
                      htmlFor='ktpUpload'
                      className='upload-doc-button mt-8'
                    >
                      Unggah Foto ID
                    </label>
                    {errors.ind_ktp_id_image && (
                      <span className=' form-error-text'>
                        {errors.ind_ktp_id_image.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='w-full flex flex-col'>
              <label
                className={`text-base text-[#081F3A] capitalize ${
                  false && 'text-red-700'
                }`}
                htmlFor={'user_bio'}
              >
                {false && <span className='requiredArt'>*</span>}
                {'Bio'}
              </label>
              <textarea
                name='bio'
                rows='4'
                id='user_bio'
                className={`!resize-none editInput placeholder:text-[#BDC1C5] border border-[#B0CAE8] p-2 focus:outline-none  rounded  ${
                  false && 'border-red-700'
                }`}
                defaultValue={user.bio}
                ref={register()}
                placeholder='Ceritakan tentang diri Anda secara singkat...'
              ></textarea>
              {errors.bio && (
                <span className=' form-error-text'>{errors.bio.message}</span>
              )}
            </div>

            {/* private account */}
            <div className='my-4   private-account'>
              <h5 className={`capitalize text-base text-[#081F3A]`}>
                Akun pribadi
              </h5>

              <p className=' text-[#A297A4] text-sm'>
                Informasi pribadi di akun Anda,
                seperti alamat email dan nomor
                telepon akan disembunyikan dari
                pengguna lain.
              </p>
            </div>

            <button
              type='submit'
              className={`edit-submit self-start capitalize text-base font-bold text-white py-3 lg:px-20 md:px-12 sm:px-6`}
              disabled={loading}
            >
              {loading && <i className='fa fa-spinner fa-pulse'></i>} Update
              Profile
            </button>

            <div className='my-4   private-account'>
              <p className=' text-[#A297A4] text-sm'>
                Anda tidak bisa mengganti data pribadi Anda di kemudian hari , pastikan Anda mengisi informasi sesuai dengan informasi yang tercantum di bukti Identitas yang dilampirkan.
              </p>
            </div>

          </div>
        </form>
      </div>
      <VerificationForm
        show={showEmailVerification}
        setShow={setShowEmailVerification}
        handleSubmit={verifyEmail}
        codeValidity={emailValidity}
        resend={sendEmail}
      />
      <VerificationForm
        show={showPhoneVerification}
        setShow={setShowPhoneVerification}
        handleSubmit={verifyPhoneNumberCode}
        codeValidity={otpValidity}
        resend={sendSMS}
        type='phone'
      />
    </>
  );
}
