import React from "react";
import axios from 'axios';
import PropTypes from "prop-types";
import { useForm, Controller } from "react-hook-form/dist/index";
import PhoneInput from "react-phone-input-2";
import flags from "react-phone-number-input/flags";
// import { serialize } from 'object-to-formdata';
import parsePhoneNumber from "libphonenumber-js";
import {
  sendEmailVerificationCode,
  verifyEmailVerificationCode,
  verifyPhoneNumber,
  verifyPhoneNumberOTP,
  updateUser,
} from "../api/user";
import VerificationForm from "./emailVerificationForm";
import mola_subscriber from "./img/mola_subscriber";

export default function UserProfileEdit(props) {
  const { user, user_attachment, user_banner, ind_ktp_id_image } = props;
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
    getValues,
    setError,
  } = useForm();
  const [validPhone, setValidPhone] = React.useState(true);
  const [validEmail, setValidEmail] = React.useState(true);
  const [molaSub, setMolaSub] = React.useState(false);
  const [verifiedEmail, setVerifiedEmail] = React.useState(user.verified_email);
  const [verifiedPhone, setVerifiedPhone] = React.useState(
    user.verified_phone_number
      ? user.verified_phone_number.replace("+", "")
      : ""
  );
  const [loading, setLoading] = React.useState(false);
  const [token, setToken] = React.useState("");
  const [showEmailVerification, setShowEmailVerification] =
    React.useState(false);
  const [showPhoneVerification, setShowPhoneVerification] =
    React.useState(false);
  const [emailValidity, setEmailValidity] = React.useState(
    user.email_code_validity ? Date.parse(user.email_code_validity) : null
  );
  const [otpValidity, setOtpValidity] = React.useState(
    user.otp_validity ? Date.parse(user.otp_validity) : null
  );
  const [phoneValidity, setPhoneValidity] = React.useState(false);
  function addServerErrors(errors, setError) {
    return Object.keys(errors).forEach((key) => {
      setError(key, {
        type: "server",
        message: errors[key].join(". "),
      });
    });
  }
  const onSubmit = (data, field = null, debounce= false) => {
    setLoading(true);
    let formUser = new FormData();
    if (data.attachment && data.attachment[0]) {
      formUser.append("user[attachment]", data.attachment[0]);
    }
    if (data.banner && data.banner[0]) {
      formUser.append("user[banner]", data.banner[0]);
    }
    if (data.ind_ktp_id_image && data.ind_ktp_id_image[0]) {
      formUser.append("user[ind_ktp_id_image]", data.ind_ktp_id_image[0]);
    }
    if (data.name) formUser.append("user[name]", data.name);
    if (data.fullname) formUser.append("user[fullname]", data.fullname);
    if (data.email) formUser.append("user[email]", data.email);
    if (data.bio) formUser.append("user[bio]", data.bio);
    if (data.personal_url)
      formUser.append("user[personal_url]", data.personal_url);
    if (data.twitter_link)
      formUser.append("user[twitter_link]", data.twitter_link);
    if (data.ind_ktp_id)
      formUser.append("user[ind_ktp_id]", data.ind_ktp_id);
    if (data.phone_number) {
      formUser.append(
        "user[phone_number]",
        `+${data.phone_number.replace("+", "")}`
      );
    }
    updateUser(formUser, token, user.id).then(
      (res) => {
        setLoading(false);
        const fieldName =
          typeof field === "string" || field instanceof String
            ? `User ${field}`
            : "Profile";
        toastr.success(`${fieldName} updated successfully`);
        if (!field) {
          location.reload();
        } else {
          if(!debounce)
            window.location.replace(document.referrer)
        }
      },
      (err) => {
        setLoading(false);
        if (err.response.data.errors) {
          addServerErrors(err.response.data.errors, setError);
        }
        console.log(err);
      }
    );
  };
  const currentEmailValue = watch("email", user.email);
  const currentPhoneValue = watch(
    "phone_number",
    user.phone_number ? user.phone_number.replace("+", "") : ""
  );
  const validateEmail = (e) => {
    const valid = !!e.target.value.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    setValidEmail(valid);
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = parsePhoneNumber(`+${e}`);
    setValidPhone(!!phoneNumber);
  };

  React.useEffect(() => {
    const element = document.querySelector("[name=csrf-token]");
    if (element) {
      setToken(element.content);
    }
  });
  React.useEffect(() => {
    const isMolaSub = async () => {
      const response = await fetch(
        `https://api2-mola.onwards.pro/v1/subscriber/queryuser?email=${currentEmailValue}`,
        {
          mode: "cors",
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Basic YWZmaWxpYXRlQG1vbGEudHY6TW9sYTIwMjI=",
          },
        }
      );
      let result = response.json();
      let activeSub = await result;
      if (activeSub.activeSubscription) {
        setMolaSub(true);
      } else {
        setMolaSub(false);
      }
    };
    return isMolaSub();
  }, []);

  React.useEffect(() => {
    const token = document.querySelector("[name=csrf-token]");
    const checkMolaSub = async() => {
      const userUpdate = await axios.put(
        '/walletless-mola-subscriber-check',
        {
          authenticity_token:
            token.content,
          mola_sub: molaSub,
          email: currentEmailValue
        }
      );
    }
    return checkMolaSub();
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    const data = {
      email: currentEmailValue,
    };
    if ((emailValidity && Date.now() > emailValidity) || !emailValidity) {
      sendEmailVerificationCode(data, token).then(
        (res) => {
          // setVerifiedEmail(false);
          toastr.success(res.message);
          setEmailValidity(Date.parse(res.code_validity));
          setShowEmailVerification(true);
        },
        (err) => {
          const error = {
            error: true,
            message: "Please input a valid email",
            status: "",
          };
          toastr.error(error.message);
          console.log(err);
        }
      );
    } else {
      setShowEmailVerification(true);
    }
  };

  const verifyEmail = (code) => {
    verifyEmailVerificationCode(
      { email: currentEmailValue, molaSub },
      code,
      token
    ).then(
      (res) => {
        setVerifiedEmail(currentEmailValue);
        toastr.success("Email verified successfully");
        setShowEmailVerification(false);
      },
      (err) => {
        toastr.error(err.response.data.message);
        console.log(err);
      }
    );
  };

  const verifyPhoneNumberCode = (code) => {
    verifyPhoneNumberOTP(
      { phone_number: `+${currentPhoneValue.replace("+", "")}` },
      token,
      code
    ).then(
      (res) => {
        setVerifiedPhone(currentPhoneValue);
        toastr.success("Phone number verified successfully");
        setShowPhoneVerification(false);
      },
      (err) => {
        toastr.error(err.response.data.message);
        console.log(err);
      }
    );
  };

  const sendSMS = (e) => {
    e.preventDefault();
    const data = { phone_number: `+${currentPhoneValue.replace("+", "")}` };
    if ((otpValidity && Date.now() > otpValidity) || !otpValidity) {
      verifyPhoneNumber(data, token).then(
        (res) => {
          toastr.success(res.message);
          setOtpValidity(Date.parse(res.otp_validity));
          setShowPhoneVerification(true);
        },
        (err) => {
          const error = {
            error: true,
            message: err.response.data.message,
            status: "",
          };
          toastr.error(error.message);
          console.log(err);
        }
      );
    } else {
      setShowPhoneVerification(true);
    }
  };

  function debounce(func, timeout = 500) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const fieldAutoSaveOnChange = (e) => {
    // console.log(e)
    const fieldName = e.target.name;
    const fieldValue = e.target.value;
    const data = {};
    data[fieldName] = fieldValue;
    console.log(data);
    onSubmit(data, fieldName, true);
  };

  return (
    <>
      <div className="edit_profile">
        <div className="row row--grid">
          <div className="col-12 col-lg-12" id="user-details-form">
            <form
              className="sign__form sign__form--profile"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="none"
            >
              <div className="row w-100percent">
                <div className="col-12">
                  <h4 className="sign__title">Profil Lengkap</h4>
                </div>
                <div className="col-12">
                  <div
                    className="sign__group upload-profile-image"
                    data-text="Change Profile Image"
                  >
                    <div className="avatar-upload border-right-lite">
                      <div className="avatar-preview">
                        <div
                          id="imagePreview"
                          style={{
                            backgroundImage: `url(${user_attachment})`,
                          }}
                        ></div>
                      </div>
                      <div className="avatar-edit">
                        <input
                          type="file"
                          name="attachment"
                          ref={register()}
                          className="form-control-file"
                          id="imageUpload"
                        />
                        <label htmlFor="imageUpload">Unggah avatar</label>
                        <p>
                          Ukuran gambar yang direkomendasikan min. 400x400{" "}
                          <br />
                          Kompatibel dengan Gifs./Gifs
                        </p>
                        {errors.attachment && (
                          <span className=" error text-danger">
                            {errors.attachment.message}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="avatar-upload">
                      <div className="avatar-preview banner_preview">
                        <div
                          id="bannerPreview"
                          style={{
                            backgroundImage: `url(${user_banner})`,
                          }}
                        ></div>
                      </div>
                      <div className="avatar-edit">
                        <input
                          type="file"
                          name="banner"
                          ref={register()}
                          className="form-control-file"
                          id="bannerUpload"
                        />
                        <label htmlFor="bannerUpload">Unggah Banner</label>
                        <p>
                          Ukuran gambar yang direkomendasikan min. 840x400{" "}
                          <br />
                          Kompatibel dengan Gifs./Gifs
                        </p>
                        {errors.banner && (
                          <span className=" error text-danger">
                            {errors.banner.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="sign__group">
                    <label htmlFor="name" className="sign__label">
                      Username Pengguna Akun* (wajib)
                    </label>
                    <input
                      type="text"
                      className="sign__input"
                      name="name"
                      ref={register({
                        required: "Please enter your username",
                      })}
                      defaultValue={user.name}
                      placeholder="Username Pengguna Akun"
                      autoComplete="false"
                      onKeyUp={debounce((e) => fieldAutoSaveOnChange(e), 500)}
                    />
                    {errors.name && (
                      <span className="error text-danger">
                        {errors.name.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="sign__group">
                    <label htmlFor="name" className="sign__label">
                      Nama Lengkap Sesuai ID* (wajib)
                    </label>
                    <input
                      type="text"
                      className="sign__input"
                      name="fullname"
                      ref={register({
                        required: "Please enter your legal name",
                      })}
                      defaultValue={user.fullname}
                      placeholder=" Nama Lengkap sesuai ID"
                      autoComplete="false"
                      onKeyUp={debounce((e) => fieldAutoSaveOnChange(e), 500)}
                    />
                    {errors.fullname && (
                      <span className=" error text-danger">
                        {errors.fullname.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="sign__group">
                    <label className="sign__label" htmlFor="email">
                      Email Anda* (wajib)
                    </label>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <input
                        type="text"
                        name="email"
                        defaultValue={user.email}
                        ref={register({
                          required: true,
                        })}
                        placeholder="johndoe@email.com"
                        className="sign__input"
                        onChange={validateEmail}
                      />
                      <span className="sign__verify">
                        {currentEmailValue &&
                          currentEmailValue != verifiedEmail &&
                          validEmail && (
                            <button
                              className="sign__verify-btn"
                              onClick={sendEmail}
                            >
                              {" "}
                              Verify
                            </button>
                          )}
                        {currentEmailValue &&
                          currentEmailValue == verifiedEmail && (
                            <button className="sign__verify-btn" disabled>
                              {" "}
                              Terverifikasi
                            </button>
                          )}
                      </span>
                      {errors.email && (
                        <span className=" error text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="sign__group">
                    <label className="sign__label" htmlFor="phone">
                      Nomor Telepon Mobile
                    </label>
                    <div
                      style={{
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <Controller
                        control={control}
                        name="phone_number"
                        // rules={{ required: true }}
                        defaultValue={user.phone_number}
                        render={(prop) => (
                          <>
                            <PhoneInput
                              {...prop}
                              country={"us"}
                              onChange={(e) => {
                                handlePhoneChange(e);
                                prop.onChange(e);
                              }}
                              inputStyle={{
                                borderRadius: "12px",
                                height: "46px",
                                width: "100%",
                                backgroundColor: "#f5f5f5",
                                border: "1px solid transparent",
                                color: "#222",
                                fontSize: "14px"
                              }}
                              buttonStyle={{
                                borderRadius: "12px 0 0 12px",
                                width: "3em",
                                backgroundColor: "#fff",
                              }}
                            />
                          </>
                        )}
                      />
                      <span className="sign__verify">
                        {currentPhoneValue &&
                          currentPhoneValue.replace("+", "") !=
                            verifiedPhone.replace("+", "") &&
                          validPhone && (
                            <button
                              className="sign__verify-btn"
                              onClick={sendSMS}
                            >
                              {" "}
                              Verify
                            </button>
                          )}
                        {currentPhoneValue &&
                          currentPhoneValue.replace("+", "") ==
                            verifiedPhone.replace("+", "") && (
                            <button className="sign__verify-btn" disabled>
                              {" "}
                              Terverifikasi
                            </button>
                          )}
                      </span>
                      {errors.phone_number && (
                        <span className=" error text-danger">
                          {errors.phone_number.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="sign__group lik_hold t_link">
                    <label className="sign__label" htmlFor="twitter_username">
                      Nomor ID Anda*  (wajib)
                    </label>
                    <input
                      type="text"
                      name="ind_ktp_id"
                      defaultValue={user.ind_ktp_id}
                      ref={register()}
                      className="sign__input"
                      id="twitter-username"
                      placeholder="Nomor ID Anda"
                      onKeyUp={debounce((e) => fieldAutoSaveOnChange(e), 500)}
                    />
                    <p id="twitt_link">Maksimal 16 karakter.</p>
                  </div>
                  {errors.ind_ktp_id && (
                    <span className=" error text-danger">
                      {errors.ind_ktp_id.message}
                    </span>
                  )}
                </div>
                {/* <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  <div className="sign__group lik_hold t_link">
                    <label
                      className="sign__label"
                      htmlFor="twitter_username"
                    >
                      Twitter Username
                    </label>
                    <input
                      type="text"
                      name="twitter_link"
                      defaultValue={
                        user.twitter_link
                      }
                      ref={register()}
                      className="sign__input"
                      id="twitter-username"
                    />
                    <span>@</span>
                    <span id="twitt_link">
                      Link
                    </span>
                    <p>
                      Link your Twitter
                      account in order
                      to get the
                      verification badge
                    </p>
                  </div>
                  {errors.twitter_link && (
                    <span className=" error text-danger">
                      {
                        errors
                          .twitter_link
                          .message
                      }
                    </span>
                  )}
                </div> */}

                <div className="col-12 col-md-6 col-lg-6 col-xl-6">
                  {/* <div className="sign__group lik_hold">
                    <label
                      className="sign__label"
                      htmlFor="portfolio_url"
                    >
                      Personal Portfolio
                    </label>
                    <input
                      type="text"
                      name="personal_url"
                      defaultValue={
                        user.personal_url
                      }
                      className="sign__input"
                      ref={register()}
                      id="personal-site-or-portfolio"
                      placeholder="Personal Portfolio"
                    />
                    <span>
                      https://
                    </span>
                  </div> */}
                  {errors.personal_url && (
                    <span className=" error text-danger">
                      {errors.personal_url.message}
                    </span>
                  )}
                  <div className="d-flex flex-column">
                    <label className="sign__label" htmlFor="mola_subscriber">
                      Paket Mola Anda Aktif
                    </label>
                    {(user.is_mola_sub) && (
                      <div className="d-flex mt-1">
                        <img
                          src={`${mola_subscriber}`}
                          alt="Mola"
                          className=" mr-2 mola_sub_img"
                        />
                        <h5>
                          Paket Mola Kamu Aktif:&nbsp;
                          <span
                            style={{
                              color: `#388E3C`,
                            }}
                          >
                            YA
                          </span>
                        </h5>
                      </div>
                    )}
                    {!user.is_mola_sub && (
                      <div className="d-flex mt-1">
                        <img
                          src={`${mola_subscriber}`}
                          alt="Mola"
                          className=" mr-2 mola_sub_img"
                        />
                        <h5>
                          Paket Mola Kamu Aktif:&nbsp;
                          <span
                            style={{
                              color: `#B71C1C`,
                            }}
                          >
                            TIDAK
                          </span>
                        </h5>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="sign__group upload-profile-image"
                    data-text="Change Profile Image"
                  >
                    <div className="avatar-upload">
                      <div className="avatar-preview ktp_preview">
                        <div
                          id="ktpPreview"
                          style={{
                            backgroundImage: `url(${ind_ktp_id_image})`,
                          }}
                        ></div>
                      </div>
                      <div className="avatar-edit">
                        <input
                          name="ind_ktp_id_image"
                          type="file"
                          ref={register()}
                          className="form-control-file"
                          id="ktpUpload"
                        />
                        <label htmlFor="ktpUpload">Unggah Foto ID</label>
                        {errors.ind_ktp_id_image && (
                          <span className=" error text-danger">
                            {errors.ind_ktp_id_image.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="sign__group">
                    <label className="sign__label" htmlFor="bio">
                      Bio
                    </label>
                    <textarea
                      name="bio"
                      rows="4"
                      id="user_bio"
                      className="sign__input"
                      defaultValue={user.bio}
                      ref={register()}
                      placeholder="Ceritakan tentang diri kamu secara singkat"
                    ></textarea>
                    {errors.bio && (
                      <span className=" error text-danger">
                        {errors.bio.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 text-center">
                  <button
                    style={{
                      width: `100%`,
                    }}
                    type="submit"
                    className="sign__btn"
                    disabled={loading}
                  >
                    {loading && <i className="fa fa-spinner fa-pulse"></i>}{" "}
                    Update Profile
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <VerificationForm
        show={showEmailVerification}
        setShow={setShowEmailVerification}
        handleSubmit={verifyEmail}
        codeValidity={emailValidity}
        resend={sendEmail}
      />
      <VerificationForm
        show={showPhoneVerification}
        setShow={setShowPhoneVerification}
        handleSubmit={verifyPhoneNumberCode}
        codeValidity={otpValidity}
        resend={sendSMS}
        type="phone"
      />
    </>
  );
}
