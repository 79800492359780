window.board = document.getElementById("board");
window.boardBox = document.getElementById("board-box");
window.zoomScaleUp = document.getElementById("zoom-scale-up");
window.zoomScaleDown = document.getElementById("zoom-scale-down");
window.seatClipBoard = document.getElementById("seat-clipboard");
window.backBtn = document.getElementById("btn-back");
window.checkoutBtn = document.getElementById("checkout-btn");
window.ticketType = document.getElementById("ticket-type");
window.totalToSelect = document.getElementById("total-to-select");
window.seatingExecBtn = document.getElementById("seating-exec-btn");
window.seatingModal = document.getElementById("seating-modal");
window.qtyInput = document.getElementById('buy_qty');
window.seatingModalCloseBtn = document.getElementById(
  "seating-modal-close-btn"
);

window.addEventListener("DOMContentLoaded", async () => {
  const collectionId = document.getElementById('collection-seating-address').value;
  const eventId = document.getElementById('event-seating-id').value;
  if (!(collectionId && eventId)) return;

  window.collectionId = collectionId;
  window.eventId = eventId;

  const seatFetch = fetch(`/customer-seating-map/${eventId}`);
  const collectionFetch = fetch(`/fetch-collection-tickets/${collectionId}`);
  const reservationFetch = fetch(
    `/collections/${collectionId}/reserved-seats/${eventId}`
  );

  if (!(seatFetch && collectionFetch && reservationFetch)) return;

  const [seatRes, collectionRes, reservationRes] = await Promise.all([
    seatFetch,
    collectionFetch,
    reservationFetch,
  ]);
console.log(seatRes)
  if (!(seatRes && collectionRes && reservationRes)) return;

  const [seatData, collectionData, reservationData] = await Promise.all([
    seatRes.json(),
    collectionRes.json(),
    reservationRes.json(),
  ]);


  let normalizedReservationData = [];
  reservationData.reserved_seats.forEach((data) => {
    normalizedReservationData = [...normalizedReservationData, ...data];
  });

  if (!(window.Ticket && window.Board)) return;

  window.Board.init(JSON.parse(seatData.seating_map_json));

  window.Ticket.setTicketName(collectionData.ticket_type);
  window.Ticket.setReservedSeats(normalizedReservationData);
});
