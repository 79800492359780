import React, { useState } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

export default function CropImageModal({
  open,
  closeModal,
  handleDeleteBanner,
  // handleSubmit,
  setBannerData,
  confirmClose,
  user,
  updateUserBanner,
}: any) {
  const [image, setImage] = useState();
  // const [cropData, setCropData] = useState('#');
  // const [doneCrop, setDoneCrop] = useState(false);
  const [cropper, setCropper] = useState<any>();
  const [loading, setLoading] = useState(false);

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  function DataURIToBlob(dataURI: any) {
    const splitDataURI = dataURI.split(',');
    const byteString =
      splitDataURI[0].indexOf('base64') >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
  }

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      // setCropData(cropper.getCroppedCanvas().toDataURL());
      // setBannerData(cropper.getCroppedCanvas().toDataURL());
      // setDoneCrop(true);

      const bannerImage = DataURIToBlob(cropper.getCroppedCanvas().toDataURL());
      setLoading(true);
      let formUser = new FormData();
      const token: any = document.querySelector('[name=csrf-token]');
      formUser.append('crop_banner_image', bannerImage);
      formUser.append('type', 'banner');
      // alert('ppp')
      updateUserBanner(formUser, token.content, user.id)
        .then((res: any) => {
          setLoading(false);
          toastr.success(res.message);
          confirmClose();
          setBannerData(cropper.getCroppedCanvas().toDataURL());
          location.reload();
        })
        .catch((err: any) => {
          setLoading(false);
          toastr.error(err.message);
          console.log(err);
        });
    }
  };

  return (
    <div
      className='upload_modal place-content-center'
      style={{ display: open ? 'grid' : 'none' }}
    >
      <div className='relative grid grid-cols-1 gap-4 justify-items-center modals-container'>
        <h4 className='modal-headers !mb-1'>Upload Banner Photo</h4>
        <button
          type='button'
          onClick={closeModal}
          className='absolute text-black top-2 right-2'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </button>
        <div className='w-full'>
          <div className='w-full'>
            <input
              type='file'
              id='uploadImage'
              onChange={onChange}
              className='hidden'
            />

            <Cropper
              style={{
                width: '100%',
                maxWidth: '100%',
                objectFit: 'contain',
                objectPosition: 'center',
                maxHeight: '200px',
              }}
              zoomTo={0}
              initialAspectRatio={1}
              preview='.img-preview'
              src={image}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
              onInitialized={(instance) => {
                setCropper(instance);
              }}
              guides={true}
            />
          </div>

          <div className='mt-2 grid grid-cols-1 !gap-2 w-full'>
            <label
              htmlFor='uploadImage'
              className=' modal-button-light  modals-btn w-full cursor-pointer  !z-50'
            >
              Select Image
            </label>
            <button
              type='button'
              onClick={handleDeleteBanner}
              className=' modal-button-red  modals-btn w-full cursor-pointer my-2 !z-50'
            >
              Delete banner
            </button>
            <button
              type='button'
              disabled={loading}
              onClick={getCropData}
              className='w-full modal-button-dark modals-btn'
            >
              {/* {loading && <i className='fa fa-spinner fa-pulse'></i>} */}
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
