import React from "react";
import Countdown, { zeroPad  } from "react-countdown";

export default function VerificationForm(props) {
  const { show, setShow, handleSubmit, type, codeValidity, resend } = props;
  const renderer = ({ hours, minutes, seconds, completed, api }) => {
    if(codeValidity > Date.now() && api.isStopped()){
      api.start()
    }
    if (completed)
      return (
        <span>
          {" "}
          <button className="modal-button-light modals-btn w-full mt-2" onClick={resend}>Resend Code</button>
        </span>
      );
    return (
      <span>
        Kirim ulang kode verifikasi dalam {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    );
  };
  return (
    <div
      className="sign__verfication_modal place-content-center"
      style={{ display: show ? "grid" : "none" }}
    >
      <div className="grid grid-cols-1 justify-items-center gap-4  modals-container">
        <button
          className="absolute top-2 right-2 text-black"
          id="verification-modal_close-btn"
          type="button"
          onClick={() => setShow(false)}
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={1.5}
            stroke='currentColor'
            className='w-6 h-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M6 18L18 6M6 6l12 12'
            />
          </svg>
        </button>
        <form
          className="text-center"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e.target[0].value);
          }}
        >
          <h5 className="mx-auto mb-3 font-bold" style={{ width: '80%' }}>
            {type == "phone"
              ? " Kami telah mengirimkan kode verifikasi ke nomor telepon Anda"
              : "Verification code has been sent to your email"}
          </h5>
          <p className="mb-1 sign__input-modal-paragraph">
            Silakan masukkan kode verifikasi Anda di bawah
          </p>
          <div className="sign_input-container mb-3">
            <input
              type="text"
              name="otp-code"
              className="sign__input"
              placeholder="Kode verifikasi"
            />
            <p className="mb-1 sign__input-modal-paragraph form-text-success text-left">
              {codeValidity && (
                <Countdown date={codeValidity} renderer={renderer} autoStart/>
              )}
            </p>
          </div>
          <div className="d-flex justify-content-center w-100percent">
            <button type="submit" className="modal-button-dark modals-btn w-full">
              Konfirmasi
            </button>
          </div>
        </form>

      </div>
    </div>
  );
}
