import axios from "axios";

window.board?.addEventListener("click", () => {
  window.Lib.emptySideBar();
  window.Lib.unselectBoardComponents();
});

window.startForm?.addEventListener("submit", async (ev) => {
  ev.preventDefault();

  let name = window.startForm.name.value;
  let width = window.startForm.width.value;
  let length = window.startForm.length.value;

  let { pvId, mapId } = await window.Lib.createSeatingMapInDB(
    name,
    width,
    length
  );
  window.location.href = `/admin/seating-map/${pvId}?mapId=${mapId}`;
});

window.toolNew?.addEventListener("click", () => {
  window.AdminSeatApp.renew();
  window.Lib.emptySideBar();
  window.Lib.slideOutToolForm();
});

window.toolArea?.addEventListener("click", () => {
  if (window.State.getDisplay() == "board") window.Lib.displayAreaForm();
});

window.toolRow?.addEventListener("click", () => {
  if (window.State.getDisplay() == "board") window.Lib.displayRowForm();
});

window.toolToggleFullscreen?.addEventListener("click", () => {
  if (!document.fullscreenEnabled) return;

  if (document.fullscreenElement) document.exitFullscreen();
  else window.root.requestFullscreen();
});

window.toolSaveJson?.addEventListener("click", async () => {
  if (window.State.getDisplay() == "board") {
    window.Lib.startSavingProgress();
    await window.Lib.saveSeatingMapToDB();
    window.Lib.endSavingProgress();
  }
});

window.toolPublishJson?.addEventListener("click", async () => {
  if (window.State.getDisplay() != "board") return;

  window.Lib.startPublishingProgress();
  await window.Lib.publishSeatingMap();
  window.Lib.endPublishingProgress();
  window.Lib.returnToAdminPage();
});

window.zoomScaleUp?.addEventListener("click", () => {
  window.State.setScale(window.State.getScale() + 0.04);
});
window.zoomScaleUp?.addEventListener("mousedown", () => {
  window.Lib.startContinousScaleUpAfter500ms();
});
window.zoomScaleUp?.addEventListener("mouseup", () => {
  window.Lib.stopContinousScale();
});
window.zoomScaleUp?.addEventListener("mouseleave", () => {
  //implemented this due to chrome bugs
  window.Lib.stopContinousScale();
});

window.zoomScaleDown?.addEventListener("click", () => {
  window.State.setScale(window.State.getScale() - 0.04);
});
window.zoomScaleDown?.addEventListener("mousedown", () => {
  window.Lib.startContinousScaleDownAfter500ms();
});
window.zoomScaleDown?.addEventListener("mouseup", () => {
  window.Lib.stopContinousScale();
});
window.zoomScaleDown?.addEventListener("mouseleave", () => {
  //implemented this due to chrome bugs
  window.Lib.stopContinousScale();
});

document.addEventListener("fullscreenchange", () => {
  if (document.fullscreenElement)
    toolToggleFullscreen.innerText = "Exit Fullscreen";
  else toolToggleFullscreen.innerText = "Enter Fullscreen";
});
