import { Controller } from "stimulus";
import axios from "axios";
import countdown from "countdown";
import Notiflix from "notiflix";

// Connects to data-controller="user-tickets"
export default class extends Controller {
  static targets = [
    "email",
    "verification",
    "confirm",
    "loading",
    "resendBtn",
    "confirmParagraph",
  ];
  connect() {}

  initialize() {

  }

  async checkMolaSub(email, token){
    let isMolaSub;
    Notiflix.Loading.arrows('')
    const response = await fetch(
      `https://api2-mola.onwards.pro/v1/subscriber/queryuser?email=${email}`,
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type":
            "application/json",
          Accept: "application/json",
          Authorization:
            "Basic YWZmaWxpYXRlQG1vbGEudHY6TW9sYTIwMjI=",
        },
      }
    );
    let result = response.json();
    let activeSub = await result;
    if (activeSub.activeSubscription) {
      isMolaSub = true;
    } else {
      isMolaSub = false;
    }
    const userUpdate = await axios.put(
      '/walletless-mola-subscriber-check',
      {
        authenticity_token: token,
        mola_sub: isMolaSub,
        email: email
      }
    );

    if(userUpdate.status === 200){
      Notiflix.Loading.remove()
      toastr.success("Verification successful");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  }

  async emailSubmit(e) {
    e.preventDefault();
    const emailForm = document.getElementById("walletless_connect_email_form");
    this.confirmTarget.disabled = true;
    this.loadingTarget.classList.remove("hidden");
    const verificationForm = document.getElementById(
      "walletless_connect_verification_form"
    );
    const element = document.querySelector("[name=csrf-token]");
    let token;
    if (element instanceof HTMLMetaElement) {
      token = element.content;
    }
    const formData = new FormData(e.target);

    axios
      .post("/walletless-login", formData, {
        headers: {
          "X-CSRF-Token": token,
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
          proccessData: true,
        },
      })
      .then(
        (response) => {
          emailForm.style.transform = "translateX(-200%)";
          toastr.success("Email sent successfully");
          verificationForm.style.transform = "translateX(0";
          this.confirmTarget.disabled = false;
          this.loadingTarget.classList.add("hidden");
          this.resendBtnTarget.disabled = true;
          console.log(this.emailTarget.value);
          this.confirmParagraphTarget.innerHTML = `Kami telah mengirimkan kode konfirmasi ke email anda' <span class="text-[#4375af]">${this.emailTarget.value}</span><br>Cek junk mail/spam folder Anda jika tidak menerima.`;
          this.resendCounter(response.data.code_invalid_by);
        },
        (error) => {
          this.confirmTarget.disabled = false;
          this.loadingTarget.classList.add("hidden");
          console.log(error.response);
          toastr.error(error.response.data.message);
        }
      );
  }

  async verificationSubmit(e) {
    e.preventDefault();
    const codeValue = document.getElementById("verification-code").value;
    const verificationForm = document.getElementById(
      "walletless_connect_verification_form"
    );
    const element = document.querySelector("[name=csrf-token]");
    let token;
    if (element instanceof HTMLMetaElement) {
      token = element.content;
    }
    axios
      .put(
        `/email-verification-code-login?verification_code=${codeValue}`,
        {},
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "*/*",
            proccessData: true,
          },
        }
      )
      .then(
        (res) => {
          this.checkMolaSub(res.data.email, token)
        },
        (err) => {
          toastr.error(err.response.data.message);
        }
      );
  }

  resendCode(e) {
    e.preventDefault();
    axios
      .get("/walletless-login-resend-code")
      .then(
        (res) => {
          this.resendBtnTarget.disabled = true;
          this.resendCounter(res.data.code_invalid_by);
          toastr.success("Email sent successfully");
        },
        (error) => {
          toastr.error(error.response.data.message);
        }
      );
  }

  resendCounter(time) {
    const resendBtn = document.querySelector("#resendBtn");
    const deadlineTime = new Date(time)
    deadlineTime.setSeconds(deadlineTime.getSeconds() + 3)
    const deadline = deadlineTime.getTime()
    console.log(deadline)


    const x = setInterval(function () {
      const now = new Date().getTime();
      const t = deadline - now;
      var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((t % (1000 * 60)) / 1000);

      resendBtn.innerText = `Kirim Ulang Kode Verifikasi ${minutes}:${seconds}`;
      if (t < 0) {
        clearInterval(x);
        resendBtn.disabled = false;

        resendBtn.innerText = "Kirim Ulang Kode Verifikasi";
      }
    }, 1000);
  }

  // later on this timer may be stopped
  // window.clearInterval(timerId);
}
