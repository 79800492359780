window.root = document.documentElement;
window.startForm = document.getElementById("start-form");
window.resizeForm = document.getElementById("resize-form");
window.board = document.getElementById("board");
window.boardBox = document.getElementById("board-box");
window.sideBarBody = document.getElementById("side-bar-body");
window.header = document.getElementById("header");
window.zoomScaleUp = document.getElementById("zoom-scale-up");
window.zoomScaleDown = document.getElementById("zoom-scale-down");

//tools
window.toolNew = document.getElementById("tool-new");
window.toolArea = document.getElementById("tool-area");
window.toolRow = document.getElementById("tool-row");
window.toolToggleFullscreen = document.getElementById("tool-toggle-fullscreen");
window.toolSaveJson = document.getElementById("tool-save-json");
window.toolPublishJson = document.getElementById("tool-publish-json");
window.toolForm = document.getElementById("tool-form");

window.addEventListener("DOMContentLoaded", () => {
  if (!window.AdminSeatApp) return;
  window.AdminSeatApp.init();
});
