import ApplicationController from './application_controller'
import axios from 'axios';
import Notiflix from "notiflix";
const FormData = require('form-data');


// Connects to data-controller="tickets"
const CustomApi = axios.create({
  baseURL: "/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": true,
  },
});
export default class extends ApplicationController {
  static targets = ["issue", 'nftAddress', 'subscriber', 'idRequired', 'availableTicket', 'download', 'userName', 'eventName', 'eventDate']
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  initialize() {
    this.issueTicket()
  }


  issueTicket() {
    const issueTabContent = document.getElementById("issue-ticket")
    if(issueTabContent) issueTabContent.style.display = "block"
  }

   async checkMolaSub(email, id, collectionId, token, path, molaSubRequired){
    let isMolaSub;
    const collection = document.getElementById("collection-id")
    Notiflix.Loading.arrows('')
    const response = await fetch(
      `https://api2-mola.onwards.pro/v1/subscriber/queryuser?email=${email}`,
      {
        mode: "cors",
        method: "GET",
        headers: {
          "Content-Type":
            "application/json",
          Accept: "application/json",
          Authorization:
            "Basic YWZmaWxpYXRlQG1vbGEudHY6TW9sYTIwMjI=",
        },
      }
    );
    let result = response.json();
    let activeSub = await result;
    if (activeSub.activeSubscription) {
      isMolaSub = true;
    } else {
      isMolaSub = false;
    }


    const userUpdate = await axios.put(
      `/update-user-mola-subscription/${id}`,
      {
        authenticity_token:
          token.content,
        mola_sub: isMolaSub,
      }
    );

    if((userUpdate.status === 200 && isMolaSub) || (userUpdate.status === 200 && molaSubRequired == '0')){
      Notiflix.Loading.remove()
      if(path === 'tickets'){
        window.location.href = `/collections/${collectionId}/issue_tickets`
      } else if (path === 'crypto'){
        window.show_modal('#Buy-modal')
      } else {
        let qty = document.getElementById('buy_qty');
        if(qty.value){
          window.location.href = `/collections/${collection.value}/set-quantity?quantity=${qty.value}`
        } else {
          window.location.href = `/collections/${collection.value}/set-quantity`
        }
      }

    } else {
      Notiflix.Loading.remove()
      window.show_modal('#molaSubscriber');
    }
  }

  async issueSeating() {
    const url = window.location.href;
    await CustomApi.get(`collections/${url.split("/")[4]}/customer-seating-map`)
  }

  close() {
    history.back()
  }

  revoke() {
    // this.stimulate('Tickets#revoke')
    console.log('hello')
  }

  async issueCollectionTicket(e) {
    e.preventDefault();
    const user = document.getElementById('user-email').value;
    const userId = document.getElementById('user-id').value;
    const molaSubRequired = '0';
    // const collectionId = document.getElementById('collection-id').value;
    const collectionId = this.data.get("myValue");
    const token = document.querySelector("[name=csrf-token]");
    this.checkMolaSub(user, userId, collectionId, token, 'tickets', molaSubRequired)
  }

  async issueCollectionTicketShow(e) {
    e.preventDefault();
    // let userE = 'peterzhang037@yopmail.com'
    const user = document.getElementById('email').value;
    const userId = document.getElementById('id').value;
    const molaSubRequired = '0';
    const token = document.querySelector("[name=csrf-token]");
    const collectionId = window.location.pathname.split('/')[2]
    this.checkMolaSub(user, userId, collectionId, token, 'tickets', molaSubRequired)
  }

  async buyWithoutCrypto(e) {
    e.preventDefault()
    const user = document.getElementById('owner-email').value;
    const id = document.getElementById('owner-id').value;
    const molaSubRequired = document.getElementById('mola-sub-required').value;
    const token = document.querySelector("[name=csrf-token]");
    const collectionId = window.location.pathname.split('/')[2]
    this.checkMolaSub(user, id, collectionId, token, 'without crypto', molaSubRequired)
  }

  async buyWithCrypto(e) {
    e.preventDefault()
    const user = document.getElementById('owner-email').value;
    const id = document.getElementById('owner-id').value;
    const molaSubRequired = document.getElementById('mola-sub-required').value;
    const token = document.querySelector("[name=csrf-token]");
    const collectionId = window.location.pathname.split('/')[2]
    this.checkMolaSub(user, id, collectionId, token, 'crypto', molaSubRequired)
  }

  downloadCollection(){
    var element = this.downloadTarget
    const userName = this.userNameTarget.innerText;
    const eventDate = this.eventDateTarget.innerText;
    const eventName = this.eventNameTarget.innerText;
    element.style.height = '100%';
    element.style.width = '570px';
    element.style.margin = '0 auto';
    // element.style.marginTop = '10px';
    element.style.backgroundColor = 'transparent';
    // element.rect(0, 0, 210, 700, "F");
    var opt = {
      filename:     `${eventName.split(' ').join('-')}_${userName.split(' ').join('-')}_${eventDate}.pdf`,
      image:        { type: 'jpg', quality: 4 },
      jsPDF:        { unit: 'mm', format: [148, 290], x: '20px', orientation: 'portrait', precision: '12', compress: true, }
    };

    html2pdf().set(opt).from(element).save();
  }

  async validateInput(e){
    const dupErrorMsg = document.getElementById('error_msg_display');
    const ticketArray = document.getElementById('allTickets');
    const allId = document.querySelectorAll('.doc-number');
    let inputs = []
    for(let i = 0; i < allId.length; i++){
      if(allId[i].value !== ''){
        inputs.push(allId[i].value)
      }
    }

    for(let i = 0; i < inputs.length; i++){
      if(ticketArray.value.includes(inputs[i])){
        dupErrorMsg.innerText = `ID number ${inputs[i]} already exists for this event`
        e.preventDefault()
        break;
      }
      if(inputs[i].length < 8 || inputs[i].length > 16){
        dupErrorMsg.innerText = 'ID Document Number must be bigger than 8 characters and smaller than 16 characters'
        e.preventDefault();
        break;
      }
      if(inputs.length !== new Set(inputs).size){
        dupErrorMsg.innerText = `ID document number must be unique. Kindly check!`
        e.preventDefault();
        break;
      }
    }
  }

  validateId(e){
    e.preventDefault();
    const value = e.target.value
    const ticketArray = document.getElementById('allTickets');
    const displayErrorMsg = document.getElementById('error_msg_display');
    const allId = document.querySelectorAll('.doc-number');
    displayErrorMsg.innerText = ''
    let arr = []
    for(let i = 0; i < allId.length; i++){
      if(allId[i].value !== ''){
       arr.push(allId[i].value)
      }
    }

    if(value.length < 8 || value.length > 16) {
      displayErrorMsg.innerText = 'ID Document Number must be bigger than 8 characters and smaller than 16 characters'
    }

    if(ticketArray.value.includes(value)){
      displayErrorMsg.innerText = 'ID document number already exists for this event';
    }

    if(arr.length !== new Set(arr).size){
      displayErrorMsg.innerText = 'ID document number must be unique';
    }
  }

  readURL(e){
    const number = this.availableTicketTarget.innerText.split(':')[1]
    for(let i = 0; i < number; i++){
      const preview = document.getElementById(`img_prev_${i}`)
      const banner = document.getElementById(`myFile_${i}`);
      var fReader = new FileReader();
      fReader.readAsDataURL(banner.files[0]);
      fReader.onloadend = function(event){
        preview.classList.remove('d-none')
          preview.src = event.target.result;
      }
    }
  }

  increaseQty(){
    let qty = document.getElementById('buy_qty');
    let newQty = document.getElementById('new-ticket-qty');
    let amountDisplay = document.getElementById('qtyAmt');
    const amount = document.getElementById('new-amount');
    const total = document.getElementById('totalAmt');
    const category = document.getElementById('category');
    console.log(category.value)
    if(qty.value !== '' && +qty.value >= 0){
      let newNum = (parseFloat(qty.value) + 1).toFixed(2);
      let product = (newNum * parseFloat(amount.value)).toFixed(2);
      if(category.value !== true){
        newQty.innerText = `${parseInt(newNum)} Tiket`
      }else {
        newQty.innerText = `Quantity ${parseInt(newNum)}`
      }

      amountDisplay.innerText = product;
      total.innerText = product;
      return qty.value = parseInt(newNum);
    }
  }

  decreaseQty(){
    let qty = document.getElementById('buy_qty');
    let newQty = document.getElementById('new-ticket-qty');
    let amountDisplay = document.getElementById('qtyAmt');
    const amount = document.getElementById('new-amount');
    const total = document.getElementById('totalAmt');
    const category = document.getElementById('category');
    if(qty.value !== '' && +qty.value >= 2){
      let newNum = (parseFloat(qty.value) - 1).toFixed(2);
      let product = (newNum * parseFloat(amount.value)).toFixed(2)
      if(category.value){
        if(+qty.value == 1){
          newQty.innerText = `${parseInt(newNum)} Tiket`
        } else {
          newQty.innerText = `${parseInt(newNum)} Tiket`;
        }
      } else {
          newQty.innerText = `Quantity ${parseInt(newNum)}`
      }
      amountDisplay.innerText = product;
      total.innerText = product;
      return qty.value = parseInt(newNum);
    }
  }

}
