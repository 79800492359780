import React, { useState, useEffect } from "react";
import MEWconnect from "@myetherwallet/mewconnect-web-client"
import Web3Modal from 'web3modal';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';

import { userLike, userUnlike } from "../../api/user"

const Like = (props) => {
  const likes = props.likes
  const address = props.address
  const collectionId = props.collectionId
  const likes_count = props.likes_count
  const isCollectionPage = props.isCollectionPage ? props.isCollectionPage : props.isCollectionPage
  const [liked, setLiked] = useState(likes.includes(collectionId));
  const [likesCount, setlikesCount] = useState(likes_count);

  useEffect(() => {
  }, [])

  const like = async () => {
    const token = document.querySelector('[name=csrf-token]').content
    // const providerOptions = {
    //   mewconnect: {
    //     package: MEWconnect,
    //     options: {
    //       infuraId: 'be9c076272484b11a0d7fae52193f281'
    //     }
    //   },
    // }
  
    // const web3Modal = new Web3Modal({
    //   network: 'mainnet',
    //   cacheProvider: true,
    //   providerOptions
    // });
    // const provider = await web3Modal.connect();
    // const web3 = new Web3(provider);

    // const webOut = await web3.eth
    // .sign("Like collectible", address)
    // .then(_signedMessage => {
    //   console.log("_signedMessage", _signedMessage)
    //   const signature = JSON.stringify(
    //     {
    //       address: address,
    //       msg: "Like collectible",
    //       sig: _signedMessage,
    //       version: '3',
    //       signer: 'MEWconnect'
    //     },
    //     null,
    //     2
    //   );
    // })
    // .catch(e => {
    //   console.log(e);
    // });
    // console.log("webOut", webOut)
    await userLike(address, collectionId, token)
    setLiked(true)
    setlikesCount(likesCount+1);
  }

  const unlike = async () => {
    const token = document.querySelector('[name=csrf-token]').content
    await userUnlike(address, collectionId, token)
    setLiked(false)
    setlikesCount(likesCount-1);
  }

  const initLike = async () => {
    if (liked) {
      await unlike()
    } else {
      await like()
    }
    // e.preventDefault()
  }


  return (
    <React.Fragment>
      {!isCollectionPage &&
          liked ?
            <button  onClick={initLike} class='mt-2'>
              <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M7.05109 0.0481718C5.30429 0.0108765 3.609 0.790782 2.50156 2.12124C0.453357 4.58451 0.330307 8.68066 3.10539 11.4649C3.1201 11.4798 3.13583 11.494 3.15182 11.5085L10.4579 17.8008C10.7672 18.0664 11.2244 18.0664 11.5334 17.8008L18.8407 11.5085C18.8567 11.494 18.8712 11.4798 18.8859 11.4649C21.661 8.6804 21.5364 4.58451 19.4885 2.12124C18.3825 0.790528 16.6857 0.0106227 14.9399 0.0481718C13.4918 0.0781096 12.0951 0.882878 10.9955 2.20039C9.89619 0.882878 8.49952 0.0781096 7.05109 0.0481718Z" fill="white"/>
              </svg>
             </button>
          : <button  onClick={initLike} class="mt-2">
              <svg width="21" height="18" viewBox="0 0 21 18" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M7.01557 1.6983C8.23794 1.72342 9.42861 2.28057 10.2821 3.75944C10.5992 4.30796 11.3908 4.30796 11.7092 3.75944C12.5629 2.28082 13.7536 1.72367 14.9757 1.6983C16.1981 1.67166 17.4494 2.24809 18.2212 3.17566C19.7105 4.96761 19.9007 8.09915 17.7204 10.293L10.9955 16.0872L4.26966 10.293C2.09055 8.09941 2.27956 4.96761 3.77011 3.17566C4.54189 2.24809 5.79319 1.67166 7.01557 1.6983ZM7.05109 0.0481718C5.30429 0.0108765 3.609 0.790781 2.50156 2.12124C0.453357 4.58451 0.330307 8.68066 3.10539 11.4649C3.1201 11.4798 3.13583 11.494 3.15182 11.5085L10.4579 17.8008C10.7672 18.0664 11.2244 18.0664 11.5334 17.8008L18.8407 11.5085C18.8567 11.494 18.8712 11.4798 18.8859 11.4649C21.661 8.6804 21.5364 4.58451 19.4885 2.12124C18.3825 0.790528 16.6857 0.0106227 14.9399 0.0481718C13.4918 0.0781096 12.0951 0.882878 10.9955 2.20039C9.89619 0.882878 8.49952 0.0781096 7.05109 0.0481718Z"
                      fill="#FEFEFF" />
              </svg>
              {/* <span>{likesCount}</span> */}
            </button>
      }

      {isCollectionPage &&
        <button  onClick={initLike} className={`card__likes heart ${liked ? 'is-active' : ''}`}>
          <svg width="21" height="18" viewBox="0 0 21 18" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M7.01557 1.6983C8.23794 1.72342 9.42861 2.28057 10.2821 3.75944C10.5992 4.30796 11.3908 4.30796 11.7092 3.75944C12.5629 2.28082 13.7536 1.72367 14.9757 1.6983C16.1981 1.67166 17.4494 2.24809 18.2212 3.17566C19.7105 4.96761 19.9007 8.09915 17.7204 10.293L10.9955 16.0872L4.26966 10.293C2.09055 8.09941 2.27956 4.96761 3.77011 3.17566C4.54189 2.24809 5.79319 1.67166 7.01557 1.6983ZM7.05109 0.0481718C5.30429 0.0108765 3.609 0.790781 2.50156 2.12124C0.453357 4.58451 0.330307 8.68066 3.10539 11.4649C3.1201 11.4798 3.13583 11.494 3.15182 11.5085L10.4579 17.8008C10.7672 18.0664 11.2244 18.0664 11.5334 17.8008L18.8407 11.5085C18.8567 11.494 18.8712 11.4798 18.8859 11.4649C21.661 8.6804 21.5364 4.58451 19.4885 2.12124C18.3825 0.790528 16.6857 0.0106227 14.9399 0.0481718C13.4918 0.0781096 12.0951 0.882878 10.9955 2.20039C9.89619 0.882878 8.49952 0.0781096 7.05109 0.0481718Z"
                  fill="#FEFEFF" />
          </svg>
          {/* <span>{likesCount}</span> */}
        </button>
      }
    </React.Fragment>
  );
}


export default Like