window.board?.addEventListener("click", (e) => {
  window.Lib.removeSeatClipboard();
});

window.boardBox?.addEventListener("scroll", () => {
  window.Lib.removeSeatClipboard();
});

window.backBtn?.addEventListener("click", () => {
  window.history.back();
});

window.zoomScaleUp?.addEventListener("click", () => {
  window.State.setScale(window.State.getScale() + 0.1);
  window.Lib.removeSeatClipboard();
});

window.zoomScaleUp?.addEventListener("mousedown", () => {
  window.Lib.startContinousScaleUpAfter500ms();
});

window.zoomScaleUp?.addEventListener("mouseup", () => {
  window.Lib.stopContinousScale();
});

window.zoomScaleUp?.addEventListener("mouseleave", () => {
  //implemented this due to chrome bugs
  window.Lib.stopContinousScale();
});

window.zoomScaleDown?.addEventListener("click", () => {
  window.State.setScale(window.State.getScale() - 0.1);
  window.Lib.removeSeatClipboard();
});

window.zoomScaleDown?.addEventListener("mousedown", () => {
  window.Lib.startContinousScaleDownAfter500ms();
});

window.zoomScaleDown?.addEventListener("mouseup", () => {
  window.Lib.stopContinousScale();
});

window.zoomScaleDown?.addEventListener("mouseleave", () => {
  //implemented this due to chrome bugs
  window.Lib.stopContinousScale();
});

window.checkoutBtn?.addEventListener("click", () => {
  if (window.checkoutBtn.classList.contains("disabled")) return;
  window.Lib.submitSeats();
});

window.seatingExecBtn?.addEventListener("click", () => {
  if(window.qtyInput.value === '' || window.qtyInput.value < 1){
    return toastr.error('Please enter quantity');
  }
  window.Ticket.setMaximumSelection(window.qtyInput.value);
  window.Lib.performSeatSelectionSideEffect(false);
  window.seatingModal.classList.add("active");
});

window.seatingModalCloseBtn?.addEventListener("click", () => {
  window.seatingModal.classList.remove("active");
});
