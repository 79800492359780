import { Controller } from "stimulus"

// Connects to data-controller="user-tickets"
export default class extends Controller {
  static targets = ["panel"]
  connect() {
  }

  initialize() {
    this.showPanel()
  }

  showPanel() {
    setTimeout(() => {
      this.element.style.display = "block"
    }, 4000);
  }

  close() {
    this.panelTarget.style.display = "none"
  }

}
