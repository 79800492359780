import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['facebook', 'twitter', 'whatsapp', 'reddit', 'copy'];
  initialize(){
    const link = encodeURI(window.location.href);
    const msg = 'Hey, take a look';
    this.facebookTarget.href = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
    this.twitterTarget.href = `https://twitter.com/intent/tweet/?&url=${link}&text=${msg}&hashtags=mola`;
    // this.linkedinTarget.href = `https://www.linkedin.com/sharing/share-offsite/?url=${link}`;
    this.redditTarget.href = `https://www.reddit.com/submit?url=${link}`;
    this.whatsappTarget.href = `https://api.whatsapp.com/send?text=${link}`;
    this.copyTarget.value = link;
  }
  shareCollection(){
    window.show_modal('#share_modal');
  }

  copyBtn() {
    this.copyTarget.select();
    document.execCommand('copy');
  }
}
