window.State = {
  globalParmas: {},
  store: {
    display: "form",
  },

  clear() {
    localStorage.removeItem("state");
    this.store = {
      display: "form",
    };

    this.init();
    return this;
  },

  getDisplay() {
    return this.store.display;
  },

  setDisplay(value) {
    if (!["form", "board"].includes(value)) throw "Unrecognized state display";

    let displayMap = {
      form: "form-container",
      board: "board-container",
    };

    for (let key in displayMap) {
      if (document.getElementById(displayMap[key]))
        if (key == value) {
          document.getElementById(displayMap[key]).style.display = "flex";
        } else {
          document.getElementById(displayMap[key]).style.display = "none";
        }
    }

    if (value == "board")
      window.Lib.enableElements(
        window.toolNew,
        window.toolArea,
        window.toolRow
      );
    else
      window.Lib.disableElements(
        window.toolNew,
        window.toolArea,
        window.toolRow
      );

    this.store.display = value;
    this.store();
  },

  init(bool) {
    this.setDisplay(bool ? "board" : "form");
  },

  getScale() {
    return this.globalParmas.scale || 1;
  },

  setScale(value) {
    value = Number(value);
    if (value < 1) return;
    if (value > 10) return;

    if (this.globalParmas.scale) {
      window.board.style.transform = `scale(${value})`;
      this.setScroll({
        x: (window.board.clientWidth * (value - this.globalParmas.scale)) / 2,
        y: (window.board.clientHeight * (value - this.globalParmas.scale)) / 2,
      });
    } else {
      this.scroll = { x: 0, y: 0 };
      window.board.style.transform = `scale(1)`;
    }

    this.globalParmas.scale = value;
  },

  setScroll({ x, y }) {
    window.boardBox.scrollBy({ left: x, top: y, behavior: "auto" });
  },

  store() {
    localStorage.setItem("state", JSON.stringify(this.store));
    return this;
  },
};
