import { Controller } from "stimulus";
import Web3Modal from "web3modal";
import Web3 from "web3";
import Notiflix from "notiflix";
import verifyWallet from "../utils/moralisFetch";
import metadataFetch from "../utils/metadataFetch";

export default class extends Controller {
  async initialize() {
    const NftId = document.getElementById('airdropNftId');
    const airdropToMeBtn = document.getElementById('airdropToMeBtn');
    const airdropType = document.getElementById('airdropType');
    const thirdPartyImages = document.getElementById('thirdPartyImages')
    const contractAddress = document.getElementById('airdropNftContractAddress')
    if (NftId) {
      const nftIds = NftId.value.split(',');
      console.log(nftIds);
      console.log(contractAddress.value);
      console.log(airdropType.value)
      if (airdropToMeBtn) {
        airdropToMeBtn.style.display = 'none';
      }

      const metadata = await metadataFetch(contractAddress.value, nftIds, 'eth')

      metadata.forEach((item) => {
        const containerDiv = document.createElement('div');
        containerDiv.className = 'col-md-3 pt-4';
        containerDiv.style.height = 'auto'
        const card = document.createElement('div');
        card.className = 'card';
        const lastDiv = document.createElement('div');
        lastDiv.className = 't_c';
        const anchor = document.createElement('a');
        card.className = 'card__cover_small';
        if(item.token_address){
          anchor.href = `https://opensea.io/assets/ethereum/${item.token_address}/${item.token_id}`;
          anchor.target = '_blank'
        } else {
          anchor.href = '#';
        }
        const image = document.createElement('img');
        let img = JSON.parse(item.metadata)
        image.src = `${img.image}`
        anchor.appendChild(image);
        lastDiv.appendChild(anchor);
        card.appendChild(lastDiv);
        containerDiv.appendChild(card);
        thirdPartyImages.appendChild(containerDiv);
      })

      if(window?.ethereum?.selectedAddress) {
        Notiflix.Loading.arrows("Verifying Wallet");

        try {
          const web3Modal = new Web3Modal();

          const connection = await web3Modal.connect();

          let providerInsatnce = new Web3(connection);

          const chainId = await providerInsatnce.eth.net.getId();
          let appChainIds;
          if ($('body').data('app-env') == 'production') {
            appChainIds = [1, 56, 137]
          } else {
            appChainIds = [5, 97, 80001]
          }

          if (typeof window.ethereum === 'undefined') {
            Notiflix.Loading.remove();

            return Notiflix.Report.failure(
              'Connect to a provider/wallet',
              '',
              'Ok'
            );
          } else if (appChainIds.indexOf(chainId) < 0) {
            Notiflix.Loading.remove();

            return Notiflix.Report.failure(
              'Invalid Network',
              'The Connected Network Is Invalid Or Not Supported',
              'Switch Network',
              function cb() {
                // openModal();
              }
            );
          }

          if (window?.ethereum?.selectedAddress === undefined) {
            Notiflix.Loading.remove();

            return Notiflix.Report.failure(
              'Session Expired',
              'You are not login. Please reload!',
              'Reconnect',
              function cb() {
                //do something
              }
            );

          }

          const walletStatus = await verifyWallet(window?.ethereum?.selectedAddress,nftIds,'eth');

          if(walletStatus === true) {
            Notiflix.Loading.remove();

            Notiflix.Notify.success("NFT Verification Successfull!");

            if (airdropToMeBtn) {
              airdropToMeBtn.style.display = 'block';
            }

            return;

          }

          Notiflix.Loading.remove();

          Notiflix.Notify.info("NFT Verification Not Successfull!")

        } catch (error) {

          Notiflix.Loading.remove();

          Notiflix.Report.failure('Error while Verifying Wallet!', error.message);

          console.log('Error while Verifying Wallet!', {...error});
        }

      }

    }
  }
}
