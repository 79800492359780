import ApplicationController from './application_controller'

export default class extends ApplicationController {
  static targets = ["price", "pricehidden", "priceIDR", "pricehiddenIDR", "priceEUR", "pricehiddenEUR"]

  quantityChange(e){
    // console.log(+this.priceTarget.value)
    const quantity = e.target.value ? e.target.value : 0
    this.priceTarget.value = +quantity * this.pricehiddenTarget.value
    if (this.priceIDRTarget) {
     this.priceIDRTarget.value = +quantity * this.pricehiddenIDRTarget.value
    }
    if (this.priceEURTarget) {
      this.priceEURTarget.value = +quantity * this.pricehiddenEURTarget.value
    }
  }
}
